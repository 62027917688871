<template>

  <!-- <img src="..." class="card-img-top" alt="..."> -->
  
<div class="row">
  <div class="col-sm-6">
    <div class="card">
        <div class="card-body"><center>
            <iframe width="600" height="370" src="https://www.youtube.com/embed/S4qw6tA7WOA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </center></div>
    </div>
  </div>
  <div class="col-sm-6">
    <div class="card">
      
        <div class="card-body">
            <h5 class="card-title">Educación y crecimiento personal</h5>
            <p class="card-text">Vivimos en un mundo socialmente afectado donde las tecnologías , el mundo virtual y las redes sociales han logrado un peso grande ejerciendo al cambio y que cada día más la educación como compromiso social tiene que adaptarse a este nuevo mundo tecnológico , la educación como base principal de la sociedad tiene como compromiso fuerte ya que la educación es uno de los factores que más influye en el avance y progreso de personas y sociedades.</p>
        </div>
      
    </div>
  </div>
</div>
  
</template>

<script>
export default {

}
</script>

<style>
    
</style>