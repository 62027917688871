<template>
  <div class="altodiv">
    <b-alert show dismissible>
        Contenido en programación.
    </b-alert>
  </div>
</template>

<script>
export default {
    name:"gobierno"
}
</script>

<style>
.altodiv{
    height: 600px;
}
</style>